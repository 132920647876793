import { Box, createStyles, Theme, withStyles } from '@material-ui/core';
import React, { Component } from 'react'

const styles = ({ breakpoints }: Theme) => createStyles({
  image: {
    maxWidth: 500,
    [breakpoints.down('sm')]: {
      width: "100%"
    }
  }
})

const disconnectLogo = require("../../assets/disconnect-white.min.gif").default

interface Props {
  classes: any
}

class IntroView extends Component<Props, {}> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <Box position="fixed" width="100%" height="100%" zIndex={2} display="flex" alignItems="center" justifyContent="center" padding={4}>
        <img src={disconnectLogo} className={classes.image}/>
      </Box>
    )
  }

}

export default withStyles(styles)(React.memo(IntroView))
