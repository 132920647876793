import { canvasRendererType, ComponentInteractionType, ISceneNode, SceneComponent } from "@mp/common";
import { canvasImageType } from "@mp/common/src/sdk-components/CanvasImage";
import { planeRendererTypeV2 } from "@mp/common/src/sdk-components/PlaneRenderer2";
import { toggleStateType } from "@mp/common/src/sdk-components/ToggleState";
import { videoRendererTypeV2 } from "@mp/common/src/sdk-components/VideoRenderer2";

type Params = {
  position: {
    x: number
    y: number
    z: number
  }
  quaternion: {
    x: number
    y: number
    z: number
    w: number
  }
  scalingFactor?: number
  width?: number
  aspectRatio?: number
  videoSrc: string
  posterSrc?: string
  stopSrc: string
  backgroundColor?: string
  sweeps: string[]
}

export class VideoNode {
  private sdk: any
  node: ISceneNode
  backgroundComponent: SceneComponent
  toggleComponent: SceneComponent
  videoComponent: SceneComponent
  posterComponent: SceneComponent
  pauseComponent: SceneComponent
  params: Params = {
    position: {
      x: 0,
      y: 0,
      z: 0
    },
    quaternion: {
      x: 0,
      y: 1,
      z: 0,
      w: 0,
    },
    scalingFactor: 1,
    width: 720,
    aspectRatio: 0.5625,
    videoSrc: null,
    posterSrc: null,
    stopSrc: null,
    backgroundColor: "#000",
    sweeps: []
  }

  constructor(sdk: any) {
    this.sdk = sdk
  }

  async create(params?: Params): Promise<ISceneNode> {
    this.params = { ...this.params, ...params }
    this.node = await this.sdk.Scene.createNode()
    this.setPosition()
    this.addBackground()
    this.addToggle()
    this.addVideo()
    this.addPoster()
    this.addPause()
    this.bindComponents()
    if (this.params.sweeps.length) {
      this.addEventListeners()
    }
    return this.node
  }

  private setPosition() {
    const { position, quaternion, scalingFactor, aspectRatio } = this.params
    this.node.position.set(position.x, position.y, position.z)
    this.node.scale.set(scalingFactor, scalingFactor * aspectRatio, scalingFactor)
    this.node.quaternion.set(quaternion.x, quaternion.y, quaternion.z, quaternion.w)
  }

  private addBackground() {
    const { backgroundColor } = this.params
    this.backgroundComponent = this.node.addComponent(planeRendererTypeV2, {
      transparent: false,
      color: backgroundColor
    })
  }

  private addToggle() {
    this.toggleComponent = this.node.addComponent(toggleStateType, { initialState: false })
  }

  private addVideo() {
    const { videoSrc } = this.params
    const renderer = this.node.addComponent(planeRendererTypeV2)
    this.videoComponent = this.node.addComponent(videoRendererTypeV2, { src: videoSrc, playing: false })
    renderer.bind("texture", this.videoComponent, "texture")
  }

  private addPoster() {
    const { width, aspectRatio, posterSrc } = this.params
    const height = width * aspectRatio
    this.posterComponent = this.node.addComponent(planeRendererTypeV2, {
      transparent: true,
      localScale: { x: 1, y: 1, z: 1 },
      localPosition: { x: 0, y: 0, z: 0.0008 },
    })
    const canvas = this.node.addComponent(canvasRendererType, {
      textureRes: {
        w: width,
        h: height
      }
    })
    const image = this.node.addComponent(canvasImageType, { 
      src: posterSrc,
      srcPosition: { x: 0, y: 0, z: 0 },
      srcSize: { w: width, h: height },
      destPosition: { x: 0, y: 0, z: 0 },
      destSize: { w: width, h: height }
    })
    canvas.bind("painter", image, "painter")
    this.posterComponent.bind("texture", canvas, "texture")
    canvas.bindEvent("repaint", image, "paint.ready")
  }

  private addPause() {
    const { aspectRatio, stopSrc } = this.params
    this.pauseComponent = this.node.addComponent(planeRendererTypeV2, {
      localPosition: { x: 0, y: 0, z: 0.0008 },
      aspect: aspectRatio
    })
    const canvas = this.node.addComponent(canvasRendererType, {
      textureRes: {
        w: 512,
        h: 512
      }
    })
    const image = this.node.addComponent(canvasImageType, { 
      src: stopSrc,
      srcPosition: { x: 0, y: 0, z: 0 },
      srcSize: { w: 512, h: 512 },
      destPosition: { x: 450, y: 340, z: .1 },
      destSize: { w: 40, h: 40 }
    })
    canvas.bind("painter", image, "painter")
    this.pauseComponent.bind("texture", canvas, "texture")
    canvas.bindEvent("repaint", image, "paint.ready")
    this.pauseComponent.bind("visible", this.toggleComponent, "state")
    this.toggleComponent.bindEvent("toggle", this.pauseComponent, ComponentInteractionType.CLICK)
  }

  private bindComponents() {
    this.videoComponent.bind("playing", this.toggleComponent, "state")
    this.toggleComponent.bindEvent("toggle", this.posterComponent, ComponentInteractionType.CLICK)
    this.posterComponent.bind("visible", this.toggleComponent, "negated")
  }

  private addEventListeners() {
    this.sdk.on(this.sdk.Sweep.Event.ENTER, (previous: string, current: string) => this.handleSweepChange(previous, current))
  }

  private handleSweepChange(_previous: string, current: string) {
    if (!this.params.sweeps.includes(current)) {
      this.toggleComponent.onEvent("off", null)
    }
  }

}

