import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Texture, VideoTexture } from 'three';

type Inputs = {
  src: MediaStream | string | HTMLVideoElement | null;
  playing: boolean | null
};

type Outputs = {
  texture: Texture | null;
  playing: boolean | null
} & ComponentOutput;

enum Event {
  Toggle = 'toggle',
  Play = 'play'
}

type Events = {
  [Event.Toggle]: boolean,
};


class VideoRendererV2 extends SceneComponent {
  private video: HTMLVideoElement;
  private texture: VideoTexture;
  
  isPlaying: boolean = false

  inputs: Inputs = {
    src: null,
    playing: null,
  }

  outputs = {
    texture: null,
    playing: null
  } as Outputs;

  events: Events = {
    [Event.Toggle]: true,
  }

  onInit() {
    //console.log("onInit()", this.inputs.src)

    const THREE = this.context.three;
    if (!this.inputs.src) {
      this.video.src = '';
      return;
    }

    if (this.inputs.src instanceof HTMLVideoElement) {
      this.video = this.inputs.src;
    } else {
      this.video = this.createVideoElement();

      if (typeof this.inputs.src === 'string') {
        this.video.src = this.inputs.src;
      } else {
        this.video.srcObject = this.inputs.src;
      }
    }
    
    this.texture = new THREE.VideoTexture(this.video);
    this.texture.minFilter = THREE.LinearFilter;
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.format = THREE.RGBFormat;
    this.outputs.texture = this.texture;


    this.video.preload = "metadata"
    this.video.addEventListener("ended", () => {
      /*
      console.log("ended")
      this.video.currentTime = 0
      this.inputs.playing = false
      this.isPlaying = false
      */
    })
    //this.video.volume = 1.0
    //this.video.controls = true
    //this.video.muted  = true
    //this.video.load()
    if (this.inputs.playing) this.video.play()
    //this.video.pause();
    //this.video.poster = this.inputs.poster


  }

  onInputsUpdated(oldInputs: Inputs) {
    //console.log("oldInputs", oldInputs)
    //this.releaseTexture()

    if (oldInputs.playing !== this.inputs.playing) {
      if (this.inputs.playing) {
        if ((window as any).gtag !== undefined) {
          /*
          (window as any).gtag('event', 'view_item', {
            items: [
              {
                id: this.video.src,
                name: this.video.src
              }
            ]
          });
          */
          (window as any).gtag("event", "video_play", {
            file: this.video.src
          });
        }
        this.video.play()
      } else {
        this.video.pause()
      }
    }
  }

  /*
  onInputsUpdated() {
    console.log('onInputsUpdated', this.inputs.src)
    this.releaseTexture();

    const THREE = this.context.three;
    if (!this.inputs.src) {
      this.video.src = '';
      return;
    }

    if (this.inputs.src instanceof HTMLVideoElement) {
      this.video = this.inputs.src;
    } else {
      this.video = this.createVideoElement();

      if (typeof this.inputs.src === 'string') {
        this.video.src = this.inputs.src;
      } else {
        this.video.srcObject = this.inputs.src;
      }

      this.video.load();
    }

    this.texture = new THREE.VideoTexture(this.video);
    this.texture.minFilter = THREE.LinearFilter;
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.format = THREE.RGBFormat;

    this.outputs.texture = this.texture;
    this.video.play();
  }
  */

  onEvent(eventType: string, eventData: unknown) {
    console.log("eventType", eventType, eventData)
    //this.video.muted = false
    if (eventType === Event.Toggle) {
      if (this.isPlaying) {
        this.video.pause()
        this.isPlaying = false
      } else {
        this.video.play().then((value: any) => {
          console.log('playing', value)
        })
        this.video.muted = false
        this.isPlaying = true
      }
    }

    if (eventType === "stop") {
      this.isPlaying = false
      this.video.pause()

    }
  }

  onDestroy() {
    //this.video.removeAttribute('src')
    //this.video.srcObject = null
    this.video.src = null
    this.releaseTexture();
  }

  releaseTexture() {
    if (this.texture) {
      this.outputs.texture = null;
      this.texture.dispose();
    }
  }

  private createVideoElement() {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous'
    video.preload = "metadata";
    (video as any).playsinline = true
    //video.autoplay = true
    //video.load()
    //video.muted = true;
    //video.loop = true;
    return video;
  }
}

export interface IVideoRendererV2 extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const videoRendererTypeV2 = 'mp.videoRenderer.v2';
export function makeVideoRendererV2() {
  return new VideoRendererV2();
}
