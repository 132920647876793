import React, { Component } from 'react';
import { Frame, GetSDK, sdkKey, initComponents, ISceneNode } from '@mp/common';
//import sceneNodes from '../sceneNodes.json'
import { createOverlayNode } from '../lib/functions'
import IntroView from './IntroView'
import { Venue, VenueName } from './ModelViewer'
import { GroupSounds } from '../sounds/GroupSounds';
import { Vector3 } from 'three';
import { VideoNode } from '../scene/VideoNode';

//const dh = process.env.NODE_ENV === "development" ? "0" : "1"


const params = {
  m: "",
  help: "0",
  play: "1",
  dh: "0",
  qs: "0",
  hr: "1",
  hl: "1",
  vr: "0",
  title: "0",
  sr: "3.08,-.02",
  brand: "0",
  maxmeshq: "256",
  maxtileq: "2048",
  maxztileq: "2048",
  applicationKey: sdkKey
}

// Vendor specific full screen functions
declare global {
  interface Document {
    readonly fullscreenElement?: Element;
    readonly mozFullScreenElement?: Element;
    readonly webkitFullscreenElement?: Element;
    readonly msFullscreenElement?: Element;

    readonly fullscreenEnabled: boolean;
    readonly mozFullScreenEnabled?: boolean;
    readonly webkitFullscreenEnabled?: boolean;
    readonly msFullscreenEnabled?: boolean;

    mozCancelFullScreen?(): void;
    webkitExitFullscreen?(): void;
    msExitFullscreen?(): void;
  }
  interface Element {
    mozRequestFullScreen?(): void;
    webkitRequestFullscreen?(): void;
    msRequestFullscreen?(): void;
  }
}

interface Props {
  venue: Venue
  muted: boolean
  matterTagsEnabled: boolean
  showDollhouse: boolean
}

interface State {
  isPlaying: boolean
}

export class MainView extends Component<Props, State> {
  private sdk: any = null;
  private groupSounds: GroupSounds
  private rootRef: React.RefObject<HTMLDivElement>;

  sceneNodes = [] as ISceneNode[]

  constructor(props: Props) {
    super(props)
    this.rootRef = React.createRef<HTMLDivElement>();
    this.state = {
      isPlaying: false
    }
    this.groupSounds = new GroupSounds(props.muted)
  }

  async sceneStart() {
    console.log("%csceneStart()", "color: blue")
    const { venue } = this.props

    if (venue.id === VenueName.HysanPlace) {
      const videoNode = new VideoNode(this.sdk)
      const videoSrc = require("../../assets/urban-sky.min.mp4").default 
      const posterSrc = require("../../assets/urban-sky-poster.min.jpg").default 
      const stopSrc = require("../../assets/stop-button.min.svg").default 
      const node = await videoNode.create({
        // x = z
        position: { x: -1.59, y: 1.98, z: -5.2 },
        quaternion: { x: 0, y: -0.707, z: 0, w: 0.707 },
        width: 720,
        aspectRatio: 0.5625,
        scalingFactor: 4.69,
        backgroundColor: "#EAEDF7",
        videoSrc: videoSrc,
        posterSrc: posterSrc,
        stopSrc: stopSrc,
        sweeps: [
          "966000178c2c462f95f2ed15f936f8e5",
          "27faeae23f0149f4ba776874c0a3c2b2",
          "fa12485ef1514c2db2e2b063fde631f2"
        ]
      })
      node.start()
      this.sceneNodes.push(node)
      console.log(`%cBuilt and started video node for ${venue.name}`, "color: blue")
    }

    if (venue.id === VenueName.PakShaRoad) {
      const videoNode = new VideoNode(this.sdk)
      // need to use direct link to asset since git runs out of memory on server when deploying
      const videoSrc = "/assets/quarantine.min.mp4"
      //const videoSrc = require("../../assets/quarantine.min.mp4").default 
      const posterSrc = require("../../assets/quarantine-poster.min.jpg").default 
      const stopSrc = require("../../assets/stop-button.min.svg").default 
      const node = await videoNode.create({
        position: { x: 0.2, y: 4.78, z: 5.05 },
        quaternion: { x: 0, y: 1, z: 0, w: 0 },
        width: 720,
        aspectRatio: 0.5625,
        scalingFactor: 3.35,
        backgroundColor: "#EAEDF7",
        videoSrc: videoSrc,
        posterSrc: posterSrc,
        stopSrc: stopSrc,
        sweeps: [
          "26e0152ce7774c96b09f21a5770e9762",
          "fc844133667a4f55b92bdfebbbd39f8b",
          "7f04ced17e7b46038c02a160aafd3627",
          "fc25f904793148a7b1a0c2a67e446c25",
          "782ed34657804156814ce20d8f4492d3",
          "6184155841f04f7faa0d0dadcc716e93", 
          "68fd6f712dd1421bb5d69663f9d37107", 
          "50f29fe2cece40d7b376de1d132e762f", 
          "f86f30dcbb9c4c759381a659bdc2120a", 
          "1d585e0ce8914d29976f368c7d6005a8", 
          "fc844133667a4f55b92bdfebbbd39f8b", 
          "b2c52ee232c2496ca4ac52a2beda687a"
        ]
      })
      node.start()
      this.sceneNodes.push(node)
      console.log(`%cBuilt and started video node for ${venue.name}`, "color: blue")

      // Adds an overlay to the doorframe in the room hosting the quarantine video - dynamically changes size to circumvent image surface problem
      const overlay1Position = new Vector3(-0.04, 4.690, -0.989)
      const overlay1Src = require("../../assets/quarantine-poster-no-play.min.jpg").default
      const overlay1Node = await createOverlayNode(this.sdk, overlay1Position, overlay1Src, 0.64)
      overlay1Node.start()
      this.sceneNodes.push(overlay1Node)
      console.log("%cBuilt overlay for doorway", "color: blue")

    }




    /*
    const overlay2Position = new Vector3(0.152, 4.824, 5.100)
    const overlay2Src = require("../../assets/quarantine-poster-no-play.min.jpg").default
    const overlay2Node = await createOverlayNode(this.sdk, overlay2Position, overlay2Src, 3)
    overlay2Node.start()
    this.sceneNodes.push(overlay2Node)
    */

  }
  
  async sceneStop() {
    console.log('sceneStop()')
    if (this.sceneNodes.length) {
      for (let i = 0; i < this.sceneNodes.length; ++i) {
        this.sceneNodes[i].stop()
      }
      this.sceneNodes = []
    }
  }

  async sdkStart() {
    console.log("%csdkStart()", "color: blue")
    this.sdk = await GetSDK('sdk-iframe', sdkKey)
    const iframeElement = document.getElementById('sdk-iframe') as HTMLIFrameElement
    this.sdk.on(this.sdk.Sweep.Event.ENTER, (oldSweep: string, newSweep: string) => this.sweepChange(oldSweep, newSweep))
    let that = this
    this.sdk.App.state.subscribe((appState: any) => {
      /*
      if (appState.phase === that.sdk.App.Phase.STARTING) {
        that.setState({ showMute: true })
      }
      */

      if (appState.phase === that.sdk.App.Phase.PLAYING) {
        this.setState({ isPlaying: true })
        window.setTimeout(() => this.hackFullscreen(iframeElement), 500)
      }
    })

    await Promise.all([
      this.sdk.Settings.update('features/measurements', false),
      this.sdk.Settings.update('features/mattertags', this.props.matterTagsEnabled),
      this.sdk.Settings.update('highlight_reel', true),
      initComponents(this.sdk),
    ])



  }


  async componentDidMount() {


    /*
    const stylesheet = document.createElement('link')
    stylesheet.rel = 'stylesheet'
    stylesheet.type = 'text/css'
    stylesheet.href = '../assets/showcase.css'
    iframeElement.allow = 'autoplay'
    */
    //iframeElement.contentDocument.getElementsByTagName('head')[0].appendChild(stylesheet);

    /*
    let container: HTMLElement = null;
    await this.sdk.Scene.configure(function (renderer: any) {
      container = renderer.domElement.parentElement;
    })
    */

    //(window as any).getPose = this.sdk.Camera.getPose;



    
    /*
    const videoComponent = node.addComponent("mp.videoRenderer")
    videoComponent.src = "/assets/quarantine.mp4"
  node.addComponent("mp.planeRenderer")
    //planeComponent.bind("texture", videoComponent)
    node.start()
    */
    //const { venue } = this.props
    console.log("%cComponentDidMount", "color: blue")
    await this.sdkStart()
    await this.sceneStart()
    
    //await this.sceneStart()
    //const serialized = await this.sdk.Scene.serialize(this.videoNodes)
    //console.log('serialized', JSON.parse(serialized))



  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.muted !== this.props.muted) {
      console.log(`componentDidUpdate() sounds muted: ${String(this.props.muted)}`)

      this.groupSounds.toggle(this.props.muted)
    }
    if (prevProps.matterTagsEnabled !== this.props.matterTagsEnabled) {
      console.log(`componentDidUpdate() matterTags enabled: ${String(this.props.matterTagsEnabled)}`)
      this.sdk.Settings.update('features/mattertags', this.props.matterTagsEnabled)
    }
  }

  async componentWillUnmount() {
    console.log('componentWillUnmount()')
    this.groupSounds.stop()
    this.sceneStop()
  }

  sweepChange(oldSweepUuid: string, newSweepUuid: string) {
    console.log(`%c${oldSweepUuid} => ${newSweepUuid}`, "color: blue")
    this.groupSounds.play(newSweepUuid)
  }

  render() {

    const { venue, showDollhouse } = this.props
    const { isPlaying } = this.state
    params.m = venue.modelSid
    params.dh = showDollhouse ? "1" : "0"
    const urlParams = new URLSearchParams(params)
    const src = `./bundle/showcase.html?${urlParams.toString()}`

    return (
      <div ref={this.rootRef}>
        {!isPlaying && <IntroView/>}
        <Frame src={src}></Frame>
      </div>
    )
  }

   private hackFullscreen(iframeElement: HTMLIFrameElement) {
     const fsElement = iframeElement.contentDocument.querySelector(
       '#fullscreen-mode .icon-fullscreen'
     )
     
     // if there is no fullscreen button, fullscreen probably isn't supported
     if (!fsElement) return;
     fsElement.addEventListener(
       'click',
       (ev) => {
         if (!this.rootRef) return;
         ev.stopImmediatePropagation();
         ev.stopPropagation();
         const rootElem = this.rootRef.current;
         const fullscreenElement =
           document.fullscreenElement ||
           document.webkitFullscreenElement ||
           document.mozFullScreenElement ||
           document.msFullscreenElement;
         const requestFullscreen =
           rootElem.requestFullscreen ||
           rootElem.webkitRequestFullscreen ||
           rootElem.mozRequestFullScreen ||
           rootElem.msRequestFullscreen;
         const exitFullscreen =
           document.exitFullscreen ||
           document.webkitExitFullscreen ||
           document.mozCancelFullScreen ||
           document.msExitFullscreen;

         if (fullscreenElement) {
           exitFullscreen.call(document);
           fsElement.classList.remove('icon-fullscreen-exit');
           fsElement.classList.add('icon-fullscreen');
         } else {
           requestFullscreen.call(rootElem);
           fsElement.classList.remove('icon-fullscreen');
           fsElement.classList.add('icon-fullscreen-exit');
         }
       },
       true
     );
 }
}
