import { sdkKey } from '@mp/common';
import * as React from 'react';
import * as ReactDom from 'react-dom';
//import { ServiceWorkerLoader } from './ServiceWorkerLoader'

console.log(`%cSDK Key=${sdkKey}`, "color: blue")
/*
console.log(`%cService worker registration skipped for development`, 'color: red')
console.log(sdkKey)
navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) {
   console.log('unregistered')
   registration.unregister()
 } })
*/

if (process.env.NODE_ENV === 'production') {
  //ServiceWorkerLoader.init()
} else {
  console.log('%cService worker registration skipped for development', 'color: blue')
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
     console.log('unregistered')
     registration.unregister()
   } })
}


import { IndexView } from './components/IndexView'

ReactDom.render(<IndexView/>, document.getElementById("content"));