
import React, { Component } from 'react'
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Theme, withStyles, useMediaQuery, GridList, GridListTile, GridListTileBar, Link, IconButton, Tooltip } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import clsx from 'clsx'
import HyperlapseVideo from './HyperlapseVideo'
import { MainView } from './MainView'

const styles = ({ spacing, breakpoints }: Theme) => createStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    pointerEvents: "none",
    padding: spacing(1),
    margin: "0 auto",
    textAlign: "center"
  },
  content: {
    padding: spacing(2),
    pointerEvents: "all"
  },
  iconButton: {
    padding: 6
  },
  gridItemOpacity: {
    opacity: 0.5
  },
  gridItemClickable: {
    cursor: "pointer"
  },
  venueSelector: {
    [breakpoints.up("md")]: {
      marginLeft: spacing(10)
    }
  }
})

export enum VenueName {
  PakShaRoad,
  HysanPlace
}

export interface Venue {
  id: VenueName
  modelSid: string
  name: string
  thumb: string
  hyperlapseVideoSrc: string
}

interface Props {
  classes: any
  isMobile: boolean
}

interface State {
  showDollhouse: boolean
  dialogOpen: boolean
  videoSrc: string
  currentVenue: Venue
  muted: boolean
  matterTagsEnabled: boolean
}

const venues: Venue[] = [
  {
    id: VenueName.PakShaRoad,
    modelSid: "Fyg6yvoay8J",
    name: "Pak Sha Road",
    thumb: require("../../assets/venue-pak-sha-road.jpg").default,
    hyperlapseVideoSrc: require("../../assets/hyperlapse-to-pak-sha-road.min.mp4").default,
  },
  {
    id: VenueName.HysanPlace,
    modelSid: "sXHXRATVa5E",
    name: "Hysan Place",
    thumb: require("../../assets/venue-hysan-place.jpg").default,
    hyperlapseVideoSrc: require("../../assets/hyperlapse-to-hysan-place.min.mp4").default,
  }
]

const isSafari = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
} 

class ModelViewer extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      showDollhouse: true,
      dialogOpen: false,
      currentVenue: venues[0],
      videoSrc: null,
      muted: isSafari(),
      matterTagsEnabled: true
    }
    this.changeVenue = this.changeVenue.bind(this)
    this.handleVideoEnded = this.handleVideoEnded.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.toggleSound = this.toggleSound.bind(this)
    this.toggleMatterTags = this.toggleMatterTags.bind(this)
  }

  async componentDidMount() {
  }

  changeVenue(newVenue: Venue) {
    // - cannot reactivate dollhouse if using setState({... showDollhouse: false})
    this.setState({ currentVenue: newVenue, videoSrc: newVenue.hyperlapseVideoSrc, dialogOpen: false })
  }

  handleVideoEnded() {
    this.setState({ videoSrc: null })
  }

  handleOpen() {
    this.setState({ dialogOpen: true })
  }

  handleClose() {
    this.setState({ dialogOpen: false })
  }

  toggleSound() {
    this.setState(prevState => ({
      muted: !prevState.muted
    }))
  }

  toggleMatterTags() {
    this.setState(prevState => ({
      matterTagsEnabled: !prevState.matterTagsEnabled
    }))
  }

  render() {
    const { classes, isMobile } = this.props
    const { dialogOpen, videoSrc, currentVenue, muted, matterTagsEnabled, showDollhouse } = this.state
    const currentVenueIndex = venues.findIndex((venue: Venue) => venue.id === currentVenue.id)
    const sortedVenues = []
    if (currentVenueIndex === 0) {
      sortedVenues.push(venues[1])
      sortedVenues.push(venues[0])
    } else {
      sortedVenues.push(venues[0])
      sortedVenues.push(venues[1])
    }
    return (
      <React.Fragment>
        {videoSrc && <HyperlapseVideo src={videoSrc} onEnd={this.handleVideoEnded}/>}
        <Box className={classes.root} display="flex">
          <Box className={classes.content} flexGrow={1}>
            <Button
              fullWidth={isMobile}
              className={classes.venueSelector}
              variant="contained"
              color="primary"
              startIcon={<LocationOnIcon/>}
              endIcon={<ExpandMoreIcon/>}
              onClick={this.handleOpen}
            >
              {currentVenue.name}
            </Button>    
          </Box>    
          <Box className={classes.content} color="#ffff">
            <Tooltip title="Mute or unmute sounds">
              <IconButton className={classes.iconButton} onClick={this.toggleSound}>
                {muted && <VolumeOffIcon color="primary"/>}
                {!muted && <VolumeUpIcon color="primary"/>}
              </IconButton>
            </Tooltip>
            <Tooltip title="Show or hide guides">
              <IconButton className={classes.iconButton} onClick={this.toggleMatterTags}>
                {matterTagsEnabled && <RadioButtonCheckedIcon color="primary"/>}
                {!matterTagsEnabled && <RadioButtonUncheckedIcon color="primary"/>}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Dialog 
          open={dialogOpen}
          onClose={this.handleClose}
          fullScreen={isMobile}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Change Venue</DialogTitle>
          <DialogContent>
            <GridList
              cellHeight={180}
              cols={isMobile ? 1 : 2}
              spacing={16}
            >
              {sortedVenues.map((venue: Venue) => {
                const isCurrent = venue.id === currentVenue.id
                const styles = clsx({
                  [classes.gridItemClickable]: !isCurrent,
                  [classes.gridItemOpacity]: isCurrent
                })
                return (
                  <GridListTile key={venue.id} component={Link} className={styles} onClick={isCurrent ? undefined : () => this.changeVenue(venue)}>
                    <img src={venue.thumb} className={classes.gridItemImage}/>
                    <GridListTileBar
                      title={venue.name}
                      subtitle={isCurrent ? "You are here" : "Visit this venue"}
                      actionIcon={!isCurrent && 
                        (
                          <IconButton color="inherit">
                            <ArrowForwardIcon/>
                          </IconButton>
                        )
                      }
                    />
                  </GridListTile>
                )
              })}
            </GridList>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {venues.map((venue: Venue) => {
          if (venue.id === currentVenue.id) return (
            <MainView key={venue.id} venue={venue} muted={muted} matterTagsEnabled={matterTagsEnabled} showDollhouse={showDollhouse}/>
          )
          return null
        })}
      </React.Fragment>
    )
  }
}

const withMediaQuery = (breakpoint: any) => (Component: any) => (props: any) => {
  const mediaProps = { isMobile: useMediaQuery(breakpoint)}
  return <Component {...mediaProps} {...props} />
}

export default withStyles(styles, { withTheme: true })(withMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))(ModelViewer))
