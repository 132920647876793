
import PSR_GROUP_1 from "./pak-sha-road-sound-group-1.json"
import PSR_GROUP_2 from "./pak-sha-road-sound-group-2.json"
import HYP_GROUP_1 from "./hysan-place-sound-group-1.json"

const VOLUME_INTERVAL = 100
const VOLUME_INCREMENT = 0.05

interface Sound {
  name: string
  filename: string
  maxVolume: number
  loop: boolean
}

interface ActiveSound {
  name: string
  filename: string
  soundObject: HTMLAudioElement
  maxVolume: number
  loop: boolean
  interval: any
}

/*

Sweep:

{
  '23435433543': [
    {
      name: "ssds"
      filename: "m4a",
      volume: 1,
      loop: true
    },
    {
      name: "ssds"
      filename: "m4a",
      volume: 1,
      loop: true
    } 
  ],
}
*/

const soundMap = new Map<string, Sound[]>()
const soundGroups = [
  {
    name: "Pak Sha Road - Group 1",
    data: PSR_GROUP_1
  },
  {
    name: "Pak Sha Raod - Group 2",
    data: PSR_GROUP_2
  },
  {
    name: "Hysan Place - Group 1",
    data: HYP_GROUP_1
  }
]

for (let index = 0; index < soundGroups.length; index++) {
  const { name, data } = soundGroups[index]  
  console.log(`%cProcessing space ${name}`, "color: blue")
  for (const [name, value] of Object.entries(data.spaces)) {
    const { sound, volume, loop, sweeps } = value 
    for (let i = 0; i < sweeps.length; i++) {
      const sweepUuid = sweeps[i].uuid
      const data = { name: name, filename: sound, loop: loop, maxVolume: volume }
      const existingSweep = soundMap.get(sweepUuid)
      if (existingSweep) {
        existingSweep.push(data)
        soundMap.set(sweepUuid, existingSweep)
      } else {
        soundMap.set(sweepUuid, [data])
      }
    }
  }
}

console.log(`%cProcessed ${soundMap.size} unique sweeps`, "color: blue")

export class GroupSounds {

  private sounds: Map<string, Sound[]>
  private activeSounds: ActiveSound[]
  private muted: boolean
  
  constructor(muted: boolean = false) {
    this.sounds = new Map<string, Sound[]>(soundMap)
    this.activeSounds = []
    this.muted = muted
  }

  findActive(sound: ActiveSound): ActiveSound|null {
    return this.activeSounds.find((activeSound: ActiveSound) => activeSound.filename === sound.filename)
  }


  queue(sounds: Sound[]): void {
    const filenames = this.activeSounds.map((activeSound: ActiveSound) => activeSound.filename)
    const queueable = sounds.filter((sound: Sound) => !filenames.includes(sound.filename))
    queueable.forEach((sound: Sound) => {
      this.addToQueue(sound)
    }) 
  }

  unqueue(sounds: Sound[]): void {
    const filenames = sounds.map((sound: Sound) => sound.filename)
    const unqueueable = this.activeSounds.filter((activeSound: ActiveSound) => !filenames.includes(activeSound.filename))
    unqueueable.forEach((activeSound: ActiveSound) => {
      this.fadeOut(activeSound)
    })
  }


  destroyActive(sound: ActiveSound): void {
    if (sound.interval) {
      clearInterval(sound.interval)
      sound.interval = null
    }
    sound.soundObject.src = null
    const index = this.activeSounds.findIndex((activeSound: ActiveSound) => activeSound.filename === sound.filename)
    if (index >= 0) {
      this.activeSounds.splice(index, 1)
    }
  }

  stop() {
    for (let i = 0; i < this.activeSounds.length; i++) {
      this.activeSounds[i].soundObject.src = null
    }
    this.activeSounds = []
  }

  toggle(mute: boolean) {
    this.muted = mute
    for (let i = 0; i < this.activeSounds.length; i++) {
      this.activeSounds[i].soundObject.muted = this.muted
    }
  }

  private fadeIn(activeSound: ActiveSound): void {
    const sound = this.findActive(activeSound)
    if (!sound) return
    if (sound.interval) {
      this.destroyActive(sound)
    }
    let { soundObject, maxVolume } = sound
    let volume = soundObject.volume
    sound.interval = setInterval(() => {
      if (volume < maxVolume) {
        volume += VOLUME_INCREMENT
        soundObject.volume = Math.min(volume, maxVolume)
      } else {
        clearInterval(sound.interval)
        sound.interval = null
      }
    }, VOLUME_INTERVAL)
  }

  private fadeOut(activeSound: ActiveSound): void {
    const sound = this.findActive(activeSound)
    if (!sound) return
    if (sound.interval) {
      this.destroyActive(sound)
    }
    let { soundObject } = sound
    let volume = soundObject.volume
    sound.interval = setInterval(() => {
      if (volume > 0) {
        volume -= VOLUME_INCREMENT
        soundObject.volume = Math.max(volume, 0)
      } else {
        clearInterval(sound.interval)
        sound.interval = null
        this.destroyActive(sound)
      }
    }, VOLUME_INTERVAL)
  }

  play(uuid: string) {
    const sounds = this.sounds.get(uuid) || []
    this.queue(sounds)
    this.unqueue(sounds)
  }

  private addToQueue(sound: Sound) {
    const { name, filename, maxVolume, loop } = sound
    const activeSound: ActiveSound = { name: name, filename: filename, soundObject: new Audio(), maxVolume: maxVolume, loop: loop, interval: null }
    activeSound.soundObject.src = require(`../../assets/sounds/${activeSound.filename}`).default as string
    activeSound.soundObject.muted = this.muted
    activeSound.soundObject.loop = activeSound.loop
    activeSound.soundObject.volume = 0.0
    /*
    const promise = activeSound.soundObject.play()
    if (promise !== undefined) {
      promise.then(() => {
        activeSound.soundObject.play()
        console.log(`%cPlaying ${filename} (${this.muted ? 'muted' : 'unmuted'})`, "color: blue")
        this.activeSounds.push(activeSound)
        console.log("activeSound", activeSound)
        this.fadeIn(activeSound)
      }).catch(reason => {
        console.error('reason', reason)
      })
    }
    */  
    /*
    activeSound.soundObject.autoplay = true
    this.activeSounds.push(activeSound)
    this.fadeIn(activeSound)
    */

    activeSound.soundObject.play().then(() => {
      console.log(`%cPlaying ${filename} (${this.muted ? 'muted' : 'unmuted'})`, "color: blue")
      this.activeSounds.push(activeSound)
      //console.log("activeSound", activeSound)
      this.fadeIn(activeSound)
    }).catch(reason => {
      console.error('reason', reason)
    })
  
  }
  
}