
import { createStyles, Theme, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

const styles = ({}: Theme) => createStyles({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
    zIndex: 2
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
})

interface Props {
  src: string
  onEnd: Function,
  classes?: any
}

class HyperlapseVideo extends Component<Props, {}> {

  private videoRef: React.RefObject<HTMLVideoElement>

  constructor(props: Props) {
    super(props)
    this.videoRef = React.createRef<HTMLVideoElement>()
  }

  componentDidMount() {
    this.videoRef.current.onended = () => {
      this.props.onEnd()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <video className={classes.video} ref={this.videoRef} playsInline src={this.props.src} autoPlay crossOrigin="anonymous"/>
      </div>
    )
  }

}



export default withStyles(styles)(React.memo(HyperlapseVideo))
