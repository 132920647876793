import { canvasRendererType, ISceneNode, planeRendererType } from "@mp/common"
import { canvasImageType } from "@mp/common/src/sdk-components/CanvasImage"
//import { toggleStateType } from "@mp/common/src/sdk-components/ToggleState"
//import { videoRendererTypeV2 } from "@mp/common/src/sdk-components/VideoRenderer2"
//import { planeRendererTypeV2 } from "@mp/common/src/sdk-components/PlaneRenderer2"

import { Vector3 } from "three"

/*
const createVideoNode = async (sdk: any) => {

  const node: ISceneNode = await sdk.Scene.createNode()

  node.name = "Video Node"
  //if (process.env.NODE_ENV === "development") {
  //  node.position.set(-0.629, -2.211, 2.432) // for dev
  //} else {
  node.position.set(0.2, 4.78, 5.05)
  //}
  const scalingFactor = 3.35
  node.scale.set(1 * scalingFactor, 0.5625 * scalingFactor, 1 * scalingFactor)
  node.quaternion.set(0, 1, 0, 0)


  node.addComponent(planeRendererTypeV2, {
    transparent: false,
    color: "#999999"
  })
  const videoPlaneRendererComponent = node.addComponent(planeRendererType)

  const toggleStateComponent = node.addComponent(toggleStateType, { initialState: false })


  const videoComponent = node.addComponent(videoRendererTypeV2, { src: "/assets/quarantine.min.mp4" })
  //videoComponent.bindEvent("toggle", videoContainerComponent, ComponentInteractionType.CLICK)
  videoPlaneRendererComponent.bind("texture", videoComponent, "texture")



  const posterPlaneRendererComponent = node.addComponent(planeRendererType, {
    transparent: true,
    localScale: { x: 1, y: 1, z: 1 },
    localPosition: { x: 0, y: 0, z: 0.0008 },
    
  })
  const posterCanvasRenderComponent = node.addComponent(canvasRendererType, {
    textureRes: {
      w: 720,
      h: 405
    }
  })
  const posterCanvasImageComponent = node.addComponent(canvasImageType, { 
    src: require("../../assets/quarantine-poster.min.jpg").default,
    srcPosition: { x: 0, y: 0, z: 0 },
    srcSize: { w: 720, h: 405 },
    destPosition: { x: 0, y: 0, z: 0 },
    destSize: { w: 720, h: 405 }
  })

  

  posterCanvasRenderComponent.bind("painter", posterCanvasImageComponent, "painter")
  
  posterPlaneRendererComponent.bind("texture", posterCanvasRenderComponent, "texture")
  posterCanvasRenderComponent.bindEvent("repaint", posterCanvasImageComponent, "paint.ready")

  //console.log(videoComponent, ComponentInteractionType)
  //videoComponent.bindEvent("toggle", posterPlaneRendererComponent, ComponentInteractionType.CLICK)

  videoComponent.bind("playing", toggleStateComponent, "state")
  toggleStateComponent.bindEvent("toggle", posterPlaneRendererComponent, ComponentInteractionType.CLICK)
  posterPlaneRendererComponent.bind("visible", toggleStateComponent, "negated")
  
  const pauseContainer = node.addComponent(planeRendererType, {
    localPosition: { x: 0, y: 0, z: 0.0008 },
    aspect: .5625
  })
  const pauseCanvas = node.addComponent(canvasRendererType, {
    textureRes: {
      w: 512,
      h: 512
    }
  })
  const pauseImage = node.addComponent(canvasImageType, { 
    src: "/assets/stop-button.min.svg",
    srcPosition: { x: 0, y: 0, z: 0 },
    srcSize: { w: 512, h: 512 },
    destPosition: { x: 450, y: 340, z: .1 },
    destSize: { w: 40, h: 40 }
  })

  pauseCanvas.bind("painter", pauseImage, "painter")
  pauseContainer.bind("texture", pauseCanvas, "texture")
  pauseCanvas.bindEvent("repaint", pauseImage, "paint.ready")
  pauseContainer.bind("visible", toggleStateComponent, "state")
  toggleStateComponent.bindEvent("toggle", pauseContainer, ComponentInteractionType.CLICK)
  
  return node
    
}
*/
const createOverlayNode = async (sdk: any, position: Vector3, src: string, scalingFactor: number) => {

  const node: ISceneNode = await sdk.Scene.createNode()
  node.position.set(position.x, position.y, position.z) 
  node.scale.set(1 * scalingFactor, 0.5625 * scalingFactor, 1 * scalingFactor)
  node.quaternion.set(0, 1, 0, 0)

  const planeRendererComponent = node.addComponent(planeRendererType, {
    transparent: true,
    localScale: { x: 1, y: 1, z: 1 },
    localPosition: { x: 0, y: 0, z: 0.01 },
    
  })
  const canvasRenderComponent = node.addComponent(canvasRendererType, {
    textureRes: {
      w: 720,
      h: 405
    }
  })
  const canvasImageComponent = node.addComponent(canvasImageType, { 
    src: src,
    srcPosition: { x: 0, y: 0, z: 0 },
    srcSize: { w: 720, h: 405 },
    destPosition: { x: 0, y: 0, z: 0 },
    destSize: { w: 720, h: 405 }
  })
  canvasRenderComponent.bind("painter", canvasImageComponent, "painter")
  planeRendererComponent.bind("texture", canvasRenderComponent, "texture")
  canvasRenderComponent.bindEvent("repaint", canvasImageComponent, "paint.ready")


  sdk.on(sdk.Sweep.Event.ENTER, (previous: string, current: string) => {
    if (current === "01ba27bffaaf450b863f5a24aee1b52b") {
      const newScale = 0.45
      node.scale.set(1 * scalingFactor * newScale, 0.5625 * scalingFactor * newScale, 1 * scalingFactor * newScale)
      node.position.set(position.x + 0.014, position.y - 0.007, position.z) 
    } else {
      node.scale.set(1 * scalingFactor, 0.5625 * scalingFactor, 1 * scalingFactor)
      node.position.set(position.x, position.y, position.z) 
    }
  })

  return node
}

export { createOverlayNode }