import React, { Component } from 'react'
import ModelViewer from './ModelViewer'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

const theme: ThemeOptions = createMuiTheme({
  palette: {
    primary: {
      main: "#fff"
    },
    background: {
      default: "#141414"
    }
  }
})

export class IndexView extends Component<{}, {}> {

  constructor(props: {}) {
    super(props)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ModelViewer />
      </ThemeProvider>
    )
  }

}